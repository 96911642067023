import * as React from 'react';
import App from '../app';
/**
 * The `content` at the index.
 */
const DebugPage = () => {
  return <App debug />;
};

export default DebugPage;
